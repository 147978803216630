.authentication-wrapper {
  width: 100%;
  display: flex;
  background-color: #F6F5F2;
}

.authentication-left {
  width: 40%;
  height: 100vh;
  position: relative;
}

.admin-icons {
  width: 100%;
  margin-bottom: 18px;
}

.admin-icons img {
  margin: auto;
}

.authentication-right {
  width: 50%;
  height: 100vh;
  position: relative;
}

.authentication-right .authentication-animation {
  width: 100%;
  mix-blend-mode: multiply;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.authentication-box {
  width: 65%;
  padding: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.authentication-title {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  color: var(--theme_text);
}

.auth-footer-head {
  font-weight: 500;
}
