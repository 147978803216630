/* SideBar */

.sidebar {
  width: 250px;
  height: 100vh;
  background-color: var(--bg-100);
  padding: 0;
  border-right: 1px solid var(--accent-100);
  position: fixed;
  top: 0;
}

.sidebar-logo {
  line-height: 60px;
  font-size: 22px;
  text-align: center;
  color: var(--primary-200);
  font-weight: 600;
}

.profile-pic {
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin: auto;
  margin-bottom: 10px;
}

.profile-pic img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.side-profile-icon-info {
  font-size: 18px;
  color: var(--primary-200);
  font-weight: 500;
}

.user-name {
  color: var(--text-100);
  text-transform: capitalize;
  font-size: 16px;
}

.designation {
  width: max-content;
  padding: 2px 10px;
  background-color: var(--accent-100);
  font-size: 12px;
  margin: auto;
  border-radius: 50px;
  color: var(--primary-200);
  font-weight: 500;
}

.sidebar-menus {
  padding: 0px 10px;
  margin-top: 18px;
}

.menu-item {
  margin-bottom: 10px;
}

.menu-link {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: var(--primary-100);
  padding: 10px;
  border-radius: 4px;
  color: var(--bg-300);
  font-size: 13px;
}

.menu-link.active-link {
  background-color: var(--primary-200);
}

.menu-link:hover {
  background-color: var(--primary-200);
}

.menu-link p {
  width: 100%;
  position: relative;
  margin: 0;
}

.menu-link p img {
  position: absolute;
  right: 0;
  top: 2px;
  transition: transform ease-in-out 0.3s;
  transition: -webkit-transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
}

.menu-with-sub-item .menu-link {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.menu-with-sub-item {
  position: relative;
}

.menu-with-sub-item:not(.menu-should-openig) .menu-link {
  border-radius: 8px;
}

.menu-sub-list {
  background-color: #d4afed;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: transform ease-in-out 0.3s;
  overflow: hidden;
}

.menu-sub-link {
  width: 100%;
  display: flex;
  gap: 14px;
  align-items: center;
  padding: 10px;
  color: var(--primary-200);
  font-size: 14px;
  font-weight: 500;
  transition: 0.5s;
}

.menu-should-openig .menu-link p img {
  transform: rotate(-90deg);
}

.menu-sub-link.active-link {
  background-color: var(--primary-200);
  color: var(--bg-300);
}

.menu-sub-link:not(.active-link):hover {
  background-color: var(--primary-200);
  color: var(--bg-300);
}


/* NavBar */

.navbar {
  width: calc(100% - 250px);
  height: 60px;
  padding: 0;
  background-color: var(--bg-100);
  border-bottom: 1px solid var(--accent-100);
  position: -webkit-sticky;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 10px;
}

.nav-title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}

.user-menu {
  list-style: none;
  position: absolute;
  right: 10px;
  top: 100%;
}

.user-menu-item {
  padding: 8px 10px;
  background-color: var(--primary-100);
  color: var(--text-300);
  cursor: pointer;
}

.user-menu-item:hover {
  background-color: var(--primary-200);
}

.user-menu-item p {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 13px;
  gap: 10px;
}

/* Container Wrapper */

.container_wrapper {
  width: calc(100% - 252px);
  margin-left: 250px;
  margin-top: 60px;
  padding: 10px;
}

.dashboard-boxs {
  display: flex;
  gap: 16px;
}

.dash-box {
  width: 100%;
  padding: 10px;
  border: 2px solid #61398f;
  border-radius: 6px;
  background: #8b5fbf;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dash-box-icons {
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.dash-box-icons img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.box-title {
  margin: 0;
  color: aliceblue;
  text-align: center;
  font-size: 16px;
}

.box-count {
  margin: 0;
  font-size: 28px;
  text-align: end;
  color: #ffffff;
}

.breadcrumbs {
  font-size: 14px;
  font-weight: 500;
}

.data-table th {
  font-size: 14px;
  font-weight: 600;
}

.data-table td {
  font-size: 14px;
}

.data-image {
  width: 240px;
  max-height: 170px;
  overflow: hidden;
}

.data-notify-image {
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.data-phones-image {
  width: 100px;
  max-height: 170px;
  overflow: hidden;
}

.data-whats-app-image {
  width: 100px;
  max-height: 170px;
  overflow: hidden;
}

.data-products {
  width: 120px;
  height: 120px;
  overflow: hidden;
  cursor: pointer;
}

.data-game {
  width: max-content;
  height: 120px;
  overflow: hidden;
}

.data-notify-image img,
.data-image img,
.data-phones-image img,
.data-products img,
.data-game img,
.data-whats-app-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.data-action {
  display: flex;
  justify-content: space-around;
}

.win-icons {
  text-align: center;
}

.win-icons img {
  cursor: pointer;
}

.paginations .pages-item {
  padding: 4px 12px;
  border: 1px solid;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  transition: .5s;
  background-color: var(--primary-100);
  color: #FFFFFF;
}

.pages-item.active {
  background-color: var(--primary-200);
}

.pages-item.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.pages-item:hover {
  background-color: var(--primary-200);
}

.action-icon {
  cursor: pointer;
}

/* Preview Banner Start */

.add-detail-main {
  width: 70%;
  margin: 0 auto;
}

.banner-preview-box {
  width: 250px;
  height: 140px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.phone-preview-box {
  width: 140px;
  height: 140px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.notify-preview-box {
  width: 150px;
  height: 150px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.banner-preview-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.data-app-log {
  width: 125px;
  height: 125px;
  overflow: hidden;
}

.data-app-log img,
.notify-preview-box img,
.phone-preview-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-preview-link {
  display: block;
  margin: 15px auto;
  padding: 10px 12px;
  text-transform: capitalize;
  cursor: pointer;
}

.add-icon {
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.add-status {
  width: 100%;
  padding: 6px 20px;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.308);
  border-radius: 4px;
}

/* Preview Banner End */

/* Product Images */

.preview-images {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
}

.preview-images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.del-preview-image {
  width: 34px;
  height: 34px;
  position: absolute;
  z-index: 1;
  background: #ffffff;
  top: 2px;
  right: 2px;
  padding: 0px;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
}

.thumb-image-status {
  width: 34px;
  height: 34px;
  position: absolute;
  z-index: 1;
  background: #ffffff;
  top: 45px;
  right: 2px;
  padding: 0px;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
}

/* Product Images End */

.logo-icon {
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin: auto;
}

.logout-account-model .title {
  color: #777;
  font-size: 16px;
  text-align: center;
  margin: 12px 0;
}

.logout-account-model .sub-title {
  font-size: 14px;
  color: #777;
  text-align: center;
  margin: 0;
  padding-bottom: 12px;
}

.logout-btns {
  margin-top: 8px;
  display: flex;
  gap: 20px;
}

.cancel-btn {
  width: 100%;
  padding: 8px 0;
  outline: none;
  border-radius: 4px;
  background: #777;
  font-weight: 500;
  color: #FFF;
  border: none;
  font-size: 16px;
  transition: 0.5s;
}

.cancel-btn:hover {
  background: #666666;
}

.logout-btn {
  width: 100%;
  padding: 8px 0;
  border: none;
  outline: none;
  border-radius: 4px;
  background: #ed322a;
  color: #fff;
  transition: 0.5s;
}

.logout-btn:hover {
  background: #c70900;
}

/* Loading animation */

.loading {
  animation: extend 3s steps(3, end) infinite;
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
}

.loading:before {
  content: "...";
}

@keyframes extend {
  0% {
    width: 0.25em;
  }

  100% {
    width: 1em;
  }
}

.terms-conditions-textarea {
  width: 100%;
}

.terms-conditions-textarea textarea {
  width: 100%;
  height: calc(100vh - 148px);
  outline: none;
  border: 2px solid var(--accent-100);
  background-color: #f3f1ff;
  resize: none;
  border-radius: 4px;
  padding: 12px;
}

.terms-conditions-textarea textarea::placeholder {
  color: var(--text-200);
  font-weight: 400;
  font-size: 14px;
}

.terms-conditions-textarea textarea:focus {
  border: 2px solid var(--accent-200);
}

.terms-conditions-textarea textarea::-webkit-scrollbar {
  display: none;
}

.export-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: 1px solid #c798ff;
  border-radius: 8px;
  cursor: pointer;
}

.export-btn.active {
  background-color: #666666;
}

.export-btn:hover {
  background-color: #eddeff;
}

.export-btn p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #000000a3;
}