@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  box-sizing: border-box;
}

:root {
  --primary-100: #8B5FBF;
  --primary-200: #61398F;
  --primary-300: #FFFFFF;

  --accent-100: #D6C6E1;
  --accent-200: #9A73B5;

  --text-100: #161616;
  --text-200: #878787;
  --text-300: #ffffff;

  --bg-100: #ede9ff;
  --bg-200: #E9E4ED;
  --bg-300: #FFFFFF;

  --red: #B80000;
}

a {
  text-decoration: none;
}

ul,
li {
  margin: 0;
  padding: 0;
}

.is_error {
  font-size: 14px;
  color: var(--red);
}

.theme_txt {
  color: var(--text-100);
}

.theme-icon-btn {
  width: max-content;
  background: var(--primary-100);
  padding: 8px;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: .5s;
  cursor: pointer;
}

.theme-icon-btn:hover {
  background-color: var(--primary-200);
}

.theme-btn {
  width: max-content;
  background: var(--primary-100);
  padding: 8px;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 13px;
  border: none;
  outline: none;
  transition: .5s;
}

.theme-btn:hover {
  background-color: var(--primary-200);
}

.theme_btn:hover {
  background-color: var(--primary-200);
}

.form-check-label {
  font-size: 14px;
}

.form_label {
  font-size: 14px;
}

.form-with-lable lable {
  display: block;
  width: 100%;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
}

.form-with-lable .input-box {
  position: relative;
}

.form-with-lable .input-box-icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(-50%, -50%);
}

.form-with-lable .input-box textarea,
.form-with-lable .input-box input {
  padding: 8px;
  background-color: #f3f1ff;
  border-radius: 4px;
  border: 2px solid var(--accent-100);
  width: 100%;
  outline: none;
  color: var(--text-100);
  font-size: 14px;
}

.form-with-lable .input-box textarea::placeholder,
.form-with-lable .input-box input::placeholder {
  color: var(--text-200);
  font-weight: 400;
  font-size: 14px;
}

.form-with-lable .input-box textarea:focus,
.form-with-lable .input-box input:focus {
  border: 2px solid var(--accent-200);
}

.form_input {
  width: 100%;
  padding: 8px 8px;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.308);
  border-radius: 4px;
  font-size: 14px;
  outline: none;
}

.form_input:focus {
  border-color: var(--primary-100);
}

.search-input {
  width: 100%;
  padding: 8px 12px;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.308);
  border-radius: 4px;
  font-size: 14px;
  outline: none;
}

.theme-mobel-header {
  border-bottom: 1px solid var(--accent-100);
  margin-bottom: 20px;
}

.theme-mobel-header .header-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
}

.theme-mobel-footer {
  display: flex;
  justify-content: end;
  gap: 15px;
}

.require-text {
  text-align: center;
  display: block;
  color: crimson;
  font-size: 14px;
}